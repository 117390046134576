import gql from 'graphql-tag'

export const ApiEndpointUpdateFragment = gql`
  fragment ApiEndpointUpdate on ApiEndpoint {
    name
    description
    method
    path
    roles
    inputParameters
    script
  }
`

export const ApiEndpointCreateFragment = gql`
  fragment ApiEndpointCreate on ApiEndpoint {
    _id
    environmentId
    createdAt
  }
`

export const EnvironmentApiFragment = gql`
  fragment EnvironmentApi on Environment {
    enableApi
    enableApiDocs
    apiAuthType
    apiAuthScript
  }
`

export const ApiEndpointFragment = gql`
  fragment ApiEndpoint on ApiEndpoint {
    ...ApiEndpointCreate
    ...ApiEndpointUpdate
  }

  ${ApiEndpointCreateFragment}
  ${ApiEndpointUpdateFragment}
`
